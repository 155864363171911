import React from "react"
import {Helmet} from "react-helmet-async"
import {graphql, Link} from "gatsby"

import Layout from "../components/layout"
import Header1 from "../components/header1"

import {GatsbySeo} from "gatsby-plugin-next-seo"

export const query = graphql`
  {
    allPrismicBlogPost(
      limit: 20
      sort: { order: DESC, fields: last_publication_date }
    ) {
      nodes {
        uid
        data {
          title {
            text
          }
          seo_summary {
            text
          }
          author_name {
            text
          }
          hero_image {
            fixed(height: 240, width: 530) {
              ...GatsbyPrismicImageFixed
            }
          }
          body {
            text
          }
        }
      }
      totalCount
    }
  }
`

const BlogOverview = ({data}) => {
  return (
    <Layout fullWave="false" bottomWave="false" callToAction="true" utmSource="blog">
      <Helmet>
        <title>LinkFive Blog</title>
      </Helmet>
      <GatsbySeo
        title="LinkFive Blog"
        description="Industry insights that get you to the next level"
        canonical={"https://www.linkfive.io/blog/"}
        openGraph={{
          url: "https://www.linkfive.io/blog/",
          title: "LinkFive Blog",
          description: "Industry insights that get you to the next level",
          site_name: "LinkFive Blog",
        }}
      />

      <section className="bg-white py-6 mt-12">
        <div className="container w-5/6 mx-auto px-2 pt-4 pb-12 text-gray-800 bg-white ">
          <Header1
            headerText="Industry insights that get you to the next level"
            widthClass="w-4/6"
          />

          <div className="md:flex md:flex-wrap lg:justify-between justify-center">
            {data.allPrismicBlogPost.nodes.map(node => {
              return (
                <>
                  <div className="md:w-1/3" key={node.uid}>
                    <Link to={node.uid} className="m-8 ">
                      {node.data.hero_image && node.data.hero_image.fixed ? (
                        <img className="object-contain mx-auto"
                             src={node.data.hero_image.fixed.src}
                             alt="Hero"
                        />
                      ) : (
                        <div
                          className="bg-gray-100"
                        ></div>
                      )}
                    </Link>
                  </div>

                  <div className="md:w-2/3 pl-6">
                    {/* <p className="uppercase py-2">Reading Time</p> */}
                    <h2 className="text-3xl pt-4">
                      <Link to={node.uid}>{node.data?.title?.text} </Link>
                    </h2>
                    {node.data.seo_summary?.text &&
                    node.data.seo_summary.text.length > 0 ? (
                      <p className="py-4">{node.data.seo_summary.text}</p>
                    ) : (
                      <p className="py-4">
                        {node.data.body.text.substring(0, 200)}...
                      </p>
                    )}

                    <p className="uppercase text-sm">
                      by {node.data.author_name?.text}
                    </p>
                  </div>
                </>
              )
            })}
          </div>

          <div className="w-5/6 mx-auto pt-20">
            <hr className="border-solid border-2 border-l5-primary"/>
            <p className="py-5 text-center text-gray-400">
              <Link to="/blog/archive">
                If you are looking for older articles, here is our archive
              </Link>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogOverview
